// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-js": () => import("./../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-how-to-help-js": () => import("./../src/templates/howToHelp.js" /* webpackChunkName: "component---src-templates-how-to-help-js" */),
  "component---src-templates-nominate-js": () => import("./../src/templates/nominate.js" /* webpackChunkName: "component---src-templates-nominate-js" */),
  "component---src-templates-stories-js": () => import("./../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */),
  "component---src-templates-our-mission-js": () => import("./../src/templates/ourMission.js" /* webpackChunkName: "component---src-templates-our-mission-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-story-js": () => import("./../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-initiative-js": () => import("./../src/templates/Initiative.js" /* webpackChunkName: "component---src-templates-initiative-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-disclaimer-js": () => import("./../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

